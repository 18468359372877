body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* load fonts here */
@font-face {
  font-family: 'Theinhardt-Medium';
  src: url(/static/media/Theinhardt-Medium.9a0ba68e.otf) format("opentype");
}

@font-face {
  font-family: 'TimesNewerRoman';
  src: url(/static/media/TimesNewerRoman.97079456.otf) format("opentype");
}


@font-face {
  font-family: 'HelveticaNeueLTStd-BlkCn';
  src: url(/static/media/HelveticaNeueLTStd-BlkCn.cee2bc72.otf) format("opentype");
}

@font-face {
  font-family: "TimesN";
  src: url(/static/media/TimesN.97079456.otf) format("opentype");
}


@font-face {
  font-family: 'Westminster';
  src: url(/static/media/Westminster.787588e6.ttf) format("truetype");
}

html{
  background-color: rgb(7, 16, 172);
}


body{
  background-color: #d6d6d6;
  background-image:  linear-gradient(#8c8c8c, #505050);
  overflow-x: hidden;
  width: 100%;

}


*{
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

h1, h2, h3, h4, p, ul{
  margin: 0;
  font-weight: inherit;
}


h4 {
  font-family: Westminster;
  font-size: 2vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  position:relative;
	padding:10px 20px;
  border-radius:25px;
}


.slider_landing_page{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6d6d6;
  position: relative;
  z-index: 10;
}

.slider_landing_page_program{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  flex-direction: column;
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.slider_landing_page_program_inner{
  align-items: baseline;
  width: 81vw;
}

.slider_landing_page_program_inner span{
  font-family: TimesNewerRoman;
  font-size: 2.7vw;
  line-height: 1.1;
  display: block;
}


.slider_landing_page_program_inner h1{
  font-family: TimesNewerRoman;
  font-size: 5.7vw;
  line-height: 1.1;
}

.slider_landing_page_program_inner h2{
  font-family: Theinhardt-Medium;
}

.program_section{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  width: 100vw;
  background-color: rgb(255, 81, 0);
  padding-top: 8vw;
  padding-bottom: 8vw;
}

#contexte{
  padding-top: 8vw;
}

.program_headline h1{
  font-family: Westminster;
  color: rgb(7, 16, 172);
  font-size: 6vw;
  margin-bottom: 6vw;
}

.program_headline h2{
  margin-bottom: 4vw;
  font-family: Theinhardt-Medium;
  font-size: 1.8vw;
}

.program_headline h2 a{
  color: rgb(7, 16, 172);
  text-decoration: none;
  display: block;
  font-family: Theinhardt-Medium;
}

.program_section section{
  width: 81vw;
  display: flex;
  align-self: baseline;
}

.program_section_first{
  border-right: 2px solid rgb(7, 16, 172);
}

.program_section_first{
  width: 50%;
}

.program_section_second{
  width: 50%;
}

.program_section section .hour{
  margin-bottom: 1vw;
}

.program_section section .hour h2{
  font-family: TimesNewerRoman;
  font-size: 1.8vw;
  color: rgb(7, 16, 172);
  border-bottom: 2px solid;
}


.program_section section .inner{
  margin-bottom: 3vw;
}

.program_section section .inner h2{
  font-family: Theinhardt-Medium;
  font-size: 1.8vw;
  color: rgb(7, 16, 172);
}



.program_section_first h2{
  margin-right: 40px;
}

.program_section_second h2{
  margin-left: 40px;
}

.hour_section{
  position: relative;
  z-index: 1000;
  background-color:transparent;
}



.slider_landing_page div{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_landing_page div{
  width: 50vw;
}

.programme_pdf h2 a{
  color: black;
}

.slider_landing_page div img{
  width: 80%;
}

.marquee_slider_landing_page p{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}


.background_landing_page_container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.background_landing_page{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.background_landing_page div{
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background_landing_page div img{
  height: 80vh;
}

.background_landing_page_images{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.background_landing_page_images p{
  font-size: 5vw;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  font-family: Theinhardt-Medium;
  margin: 0;
}


.full_main_container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 1;
}

.full_main_container_img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}


.full_main_container_img img{
  width: 50vw;
  -webkit-animation:spin 10s linear infinite;
          animation:spin 10s linear infinite;
}

header{
  width: 90vw;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-top: 1vw;
  position: fixed;
  top: 0;
}

header div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


header div h1{
  font-size: 7vw;
  font-family: Theinhardt-Medium;
  -webkit-text-stroke: 1px rgb(7, 16, 172);
  text-align: center;
  color: rgb(255, 81, 0);
  line-height: 1;
  font-weight: lighter;
}

header div h1 span{
  font-family: TimesNewerRoman;
  -webkit-text-fill-color: initial;
  color: rgb(255, 81, 0);
  -webkit-text-stroke: 1px rgb(7, 16, 172);
}


footer{
  width: 90vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 1vw;
}

footer div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


footer div h1{
  font-size: 7vw;
  color: black;
  font-family: Theinhardt-Medium;
  text-align: center;
  font-family: Theinhardt-Medium;
  -webkit-text-stroke: 1px rgb(7, 16, 172);
  color: rgb(255, 81, 0);
}

footer div h1 span{
  font-family: TimesNewerRoman;
  -webkit-text-stroke: 1px rgb(7, 16, 172);
  color: rgb(255, 81, 0);
}

.full_committee_container,
.full_program_container{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding-bottom: 4vw; */
}


.full_committee_container_inner{
  padding-top: 8vw;
  width: 81vw;
  display: flex;
  align-items: baseline;
}

.full_program_container_inner{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.full_program_container_cell{
  width: 100%;
  padding-bottom: 4vw;
  padding-top: 4vw;
}

.full_program_container_cell span{
  line-height: 1.4;
  font-family: Theinhardt-Medium;
  font-size: 4vw;
  color: rgb(7, 16, 172);
}

.full_program_container_cell h2{
  font-family: Theinhardt-Medium;
  font-size: 4.6vw;
  color: rgb(255, 81, 0);
  line-height: 1;
  -webkit-text-stroke: 0.5px rgb(7, 16, 172);
}

.full_program_container_cell h3{
  color: rgb(7, 16, 172);
  font-family: TimesNewerRoman;
  font-size: 3vw;
}

.full_committee_container_inner div{
  width: 50%;
}

.full_committee_container_inner div:first-child{
  border-right: 2px solid rgb(7, 16, 172);
  padding-right: 40px;
}

.full_committee_container_inner div:last-child{
  margin-left: 40px;
}

.full_committee_container_inner div h4{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-wrap: break-word;
  position: relative;
  line-height: 1.4;
  color: rgb(7, 16, 172);
  font-family: TimesNewerRoman;
  font-size: 1.8vw;
  margin: 0;
  padding: 0;
}



.full_committee_container_inner div p span{
  color: black;
}


.guidelines_and_button_container,
.notes_container,
.full_title_container,
.bottom_info_main_container,
.credits_main_container,
.inscription_button_main_container,
.main_text_container{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 8vw;
}

.credits_main_container{
  padding-bottom: 0;
}

.proposition_text_main_container{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 4vw;
}



.sticky_container{
  padding: 2vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
  position: fixed;
}

.sticky_container a{
  color: inherit;
  text-decoration: none;
}

.sticky_container ul{
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1vw;
}

.sticky_container ul div img{
    height: 2.5vw;
}


.sticky_container ul li{
  font-size: 1.45vw;
  font-family: Westminster;
  color: rgb(7, 16, 172);
  text-decoration: none;
  margin-bottom: 1vw;
  display: flex;
  align-items: flex-end;
}




.hover_sticky{
  background-color: rgb(255, 81, 0);
  margin-left: 3vw;
  border-radius: 20px;
  padding: 1vw;
}



.sticky_container ul li:last-child{
  margin-bottom: 0;
}

.guidelines_and_button_container{
  padding-bottom: 0;
}

nav{
  position: fixed;
}


.proposition_text_main_container_inner,
.notes_container_inner,
.bottom_info_container_inner,
.inscription_button_main_container_inner,
.credits_main_container_inner,
.full_main_container_inner{
  width: 81vw;
  padding-top: 6vw;
}

#infos_pratiques{
  padding-bottom: 0;
}

.credits_main_container_inner{
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 1vw;
  width: 100vw;
  background-color: rgb(255, 81, 0);
  z-index: 100;
}

.credits_main_container_inner div{
  width: 50%;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid rgb(7, 16, 172);
}

.credits_main_container_inner div h2{
  color: rgb(7, 16, 172);
  font-family: TimesNewerRoman;
  font-size: 1.52vw;
}
.credits_main_container_inner div h2 a{
      color: rgb(7, 16, 172);
}

.inscription_button_main_container_inner img{
  width: 50vw;
  z-index: 0;
}

.bottom_info_container_inner{
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.bottom_info_container_secondary{
  display: flex;
  align-items: baseline;
  margin-top: 4vw;
}

.bottom_info_container_secondary div{
  width: 50%;
}

.bottom_info_container_secondary div:first-child{
  border-right: 2px solid rgb(7, 16, 172);
  padding-right: 40px;
}

.bottom_info_container_secondary div:last-child{
  padding-left: 40px;
}

.bottom_info_container_secondary div h1{
  line-height: 1.4;
  font-family: TimesNewerRoman;
  font-size: 2.6vw;
  color: rgb(7, 16, 172);
}

.bottom_info_container_secondary div h1 a{
  color: rgb(7, 16, 172);
}

/* .bottom_info_container_inner div{
  width: 50%;
} */

/* .bottom_info_container_inner div:first-child{
  border-right: 2px solid rgb(7, 16, 172);
  padding-right: 40px;
}

.bottom_info_container_inner div:last-child{
  padding-left: 40px;
} */

.bottom_info_container_body{
  margin-top: 4vw;
}

.bottom_info_container_body h1{
  font-family: Theinhardt-Medium;
  font-size: 4.8vw;
  color: rgb(255, 81, 0);
  line-height: 1.4;
  -webkit-text-stroke: 0.5px rgb(7, 16, 172);
}

.bottom_info_container_body h1 span{
  font-family: TimesNewerRoman;
  font-size: 5vw;
}

.bottom_info_container_headlines h1{
  width: 100%;
font-family: Westminster;
font-size: 6vw;
line-height: 1.4;
color: black;
color: rgb(7, 16, 172);
}

.guidelines_and_button_container_inner{
  width: 81vw;
  padding-bottom: 8vw;
  padding-top: 4vw;
}


.main_text_container_inner{
  padding-top: 0  ;
  width: 81vw;
}

.full_main_container_inner h1{
  font-size: 2.8vw;
  color: rgb(7, 16, 172);
  font-family: Theinhardt-Medium;
}

.full_main_container_inner h2{
  font-size: 2.8vw;
  font-family: Theinhardt-Medium;
  text-transform: uppercase;
  color: rgb(7, 16, 172);
}

.full_main_container_inner h3{
  font-family: TimesNewerRoman;
  color: black;
  font-size: 2.8vw;
  margin-top: 3vw;
}

.full_main_container_inner h1 span{
  font-family: Theinhardt-Medium;
  color: rgb(7, 16, 172);
  font-style: italic;
  font-size: 2.8vw;
  text-decoration: underline;
}

.notes_container_inner{
  padding-top: 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.notes_container_inner div{
  width: 25%;
}

.notes_container_inner div:first-child{
  padding-left: 0;
}

.notes_container_inner div:last-child{
  padding-right: 0;
}

.notes_container_inner div p{
  color: rgb(7, 16, 172);
  font-family: TimesNewerRoman;
  font-size: 1.4vw;
  padding: 1.2vw;
  padding-bottom: 0;
}

.notes_container_inner div p span{
  font-family: Westminster;
  color: rgb(7, 16, 172);
}

.notes_container_inner div p:first-child{
  padding-left: 0;
}



.main_text_container_inner_contexte_headline{
  display: flex;
  margin-bottom: 8vw;
}

.main_text_container_inner_contexte a{
  font-family: Theinhardt-Medium;
  color: black;
}

.main_text_container_inner_contexte p {
  line-height: 1.4;
  color: black;
  font-family: Theinhardt-Medium;
  font-size: 1.8vw;
  margin-top: 2vw;
}

.main_text_container_inner_contexte span{
  font-family: Westminster;
  font-size: 6vw;
  line-height: 1.4;
  color: rgb(7, 16, 172);
}

.proposition_text_main_container_inner
.proposition_text_main_container_inner_headlines{
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 8vw;
  flex-direction: column;
}

.proposition_text_main_container_inner
.proposition_text_main_container_inner_headlines span{
  width: 100%;
  font-family: Westminster;
  font-size: 6vw;
  line-height: 1.4;
  color: black;
  color: rgb(7, 16, 172);
}


.main_text_container_inner_argument{
  margin-top: 8vw;
  display: flex;
  width: 81vw;
}

.main_text_container_inner_argument div {
    width: 50%;
}

.main_text_container_inner_argument_body{
  width: 81vw;
  display: flex;
  align-items: baseline;
}


.main_text_container_inner_argument_body div{
  line-height: 1.4;
  color: black;
  font-family: TimesNewerRoman;
  font-size: 1.52vw;
  margin-top: 4vw;
  width: 50%;
}

.main_text_container_inner_argument_body_last{
  font-family: Westminster;
  color: rgb(7, 16, 172);
  margin-top: 4vw;
  font-size: 3vw;
  font-weight: 100;
  border-bottom: 0;
  width: 81vw;
}

/* .main_text_container_inner_argument_body div:last-child{
  font-family: Westminster;
  color: rgb(7, 16, 172);
  font-size: 3vw;
  font-weight: 100;
  border-bottom: 0;
} */

.main_text_container_inner_argument_body p{
  margin-bottom: 3vw;
}

.main_text_container_inner_argument_body div:first-child{
  margin-left: 0;
  padding-left: 0;
  padding-right: 40px;
  border-right: 2px solid rgb(7, 16, 172);
}

.main_text_container_inner_argument_body div:last-child{
    border-right: none;
    padding-left: 40px;
}

.main_text_container_inner_argument div span{
  font-family: Westminster;
  font-size: 6vw;
  line-height: 1.4;
  color: rgb(7, 16, 172);
}

.main_text_container_inner_argument div p{
  font-family: TimesNewerRoman;
  margin-bottom: 2vw;
  font-size: 1.45vw;
  line-height: 1.4;
  color: black;
  margin-bottom: 2vw;
}

.main_text_container_inner_argument div:first-child p:first-child{
  margin-top: 4vw;
}


.main_text_container_inner_contexte p span,
.main_text_container_inner_argument div p span{
  display: block;
  margin-bottom: 2vw;
  font-family: Westminster;
  font-weight: 100;
  font-size: 2.3vw;
  color: rgb(7, 16, 172);
  line-height: 0.8;
  font-style: italic;
  }





.proposition_text_main_container p{
  font-family: Theinhardt-Medium;
  font-size: 1.8vw;
  line-height: 1.4;
  color: black;
}


.proposition_text_div_button,
.proposition_text_div_cta{
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-top: 4vw;
}

.proposition_text_div_cta{
  margin-bottom: 4vw;
}

.proposition_text_div_button span{
  width: 50%;
  color: black;
  padding-right: 1.55vw;
  font-family: TimesNewerRoman;
  margin-bottom: 2vw;
  font-size: 1.45vw;
  line-height: 1.4;
  padding-left: 0;
}


/* fix this */
.proposition_text_div_button span:last-child{
  padding-left: 63px;
}

.proposition_text_div_cta span{
  width: 100%;
  color: black;
  text-align: center;
  font-family: Theinhardt-Medium;
  font-size: 4.1vw;
  line-height: 1.4;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  text-transform: uppercase;
}



.proposition_text_main_container_span_list_container{
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 8vw;
}

.proposition_text_main_container_span_list_container div{
  width: 50%;
  padding-right: 1.55vw;
}

.proposition_text_main_container_span_list_container div:first-child{
  border-right: 2px solid rgb(7, 16, 172);
}


.proposition_text_main_container_span_list_container h1{
  font-family: Westminster;
  font-size: 3vw;
  margin-bottom: 3vw;
  padding-bottom: 2vw;
  color: rgb(7, 16, 172);
}

.proposition_text_main_container_span_list_container ul {
  padding-left: 20px;
}

.proposition_text_main_container_span_list_container .last_ul{
  padding-left: 40px;
}


.proposition_text_main_container_span_list_container ul li{
  font-family: TimesNewerRoman;
  margin-bottom: 2vw;
  font-size: 1.52vw;
  line-height: 1.4;
  color: black;
}

.proposition_text_main_container_span_list_container ul li span{
  display: block;
    margin-bottom: 2vw;
    font-family: Westminster;
    font-weight: 100;
    font-size: 3vw;
    color: rgb(7, 16, 172);
}


.proposition_text_main_container_span_list ul li:first-child,
.last_li{
  list-style-type: none;
}

/* bad practice! */
.last_li{
  font-family: Westminster !important;
  color: rgb(7, 16, 172) !important;
  font-size: 3vw !important;
  font-weight: 100 !important;
  margin-top: 2vw;
}


.proposition_text_main_container_title_list_container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.guidelines_container{
  display: flex;
  align-items: baseline;
}

.guidelines_container div{
  font-family: TimesNewerRoman;
  font-size: 1.45vw;
  line-height: 1.4;
  color: black;
  width: 50%;
}

.guidelines_container div:first-child{
  padding-right: 40px;
  border-right: 2px solid rgb(7, 16, 172);
}

.guidelines_container div:last-child{
  padding-left: 40px;
}

.guidelines_container div span{
  color: rgb(7, 16, 172);
  font-family: Westminster;
  display: block;
  font-size: 3vw;
  margin-bottom: 2vw;
}

.button_container{
  font-family: Theinhardt-Medium;
  font-size: 4vw;
  color: rgb(255, 81, 0);
  margin-top: 6vw;
  text-transform: uppercase;
  line-height: 0;
}

.button_container span{
  padding: 3vw;
  padding-top: 6vw;
  padding-bottom: 6vw;
  line-height: 0;
  border: 2px solid rgb(7, 16, 172);
  border-radius: 30px;
  background-color: white;
}

.cta_container_inner{
  width: 81vw;
  display: flex;
  align-items: center;
  padding-bottom: 8vw;
  margin-top: 8vw;
  position: relative;
  z-index: 10;
}

.cta_container_inner img{
  width: 50vw;
}

.cta_container_inner p{
  width: 81vw;
  font-size: 4.7vw;
  font-family: Theinhardt-Medium;
  line-height: 0;
}


.chat {
  display: flex;
  flex-direction: column;
  width: 81vw;
}

.messages {
  margin-top: 8vw;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  background-color: rgb(255, 81, 0);
  position: relative;
  font-family: Theinhardt-Medium;
  color: rgb(7, 16, 172);
  font-size: 11vw;
  padding-top: 3vw;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: rgb(255, 81, 0);
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: #545454;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: rgb(0, 120, 254);
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: rgb(0, 120, 254);
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}


.cta_container_inner p span{
  padding: 3vw;
  color: rgb(7, 16, 172);
  background-color: rgb(255, 81, 0);
  text-transform: uppercase;
  border-radius: 30px;
}


sup{
  margin: 0;
  font-family: Westminster;
  color: rgb(7, 16, 172);
}

/* keyframes */

@-webkit-keyframes color_shifting {
  0% {
      color: rgb(255, 81, 0);
  }
  50% {
      color: rgb(0, 195, 52);
  }
  100% {
    color: rgb(255, 81, 0);
  }
}

@keyframes color_shifting {
  0% {
      color: rgb(255, 81, 0);
  }
  50% {
      color: rgb(0, 195, 52);
  }
  100% {
    color: rgb(255, 81, 0);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg); }
  }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg); }
  }


@-webkit-keyframes spinReverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform:rotate(-360deg); }
  }


@keyframes spinReverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform:rotate(-360deg); }
  }


/* mobile  both here*/

  @media screen and (max-width: 520px) {
    .full_main_container_img img{
      width: 80vw;
      -webkit-animation:spin 10s linear infinite;
              animation:spin 10s linear infinite;
    }


    header{
      width: 90vw;
      display: flex;
      align-items: baseline;
      flex-direction: column;
      margin-top: 8vw;
      position: fixed;
      top: 0;
    }


    header div h1{
      font-size: 10vw;
      font-family: Theinhardt-Medium;
      -webkit-text-stroke: 1px rgb(7, 16, 172);
      text-align: center;
      color: rgb(255, 81, 0);
      line-height: 1;
      font-weight: lighter;
    }

    header div h1 span{
      font-family: TimesNewerRoman;
      -webkit-text-fill-color: initial;
      color: rgb(255, 81, 0);
      -webkit-text-stroke: 1px rgb(7, 16, 172);
    }


    footer{
      width: 90vw;
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: baseline;
      flex-direction: column;
      margin-bottom: 8vw;
    }



    footer div{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    footer div h1{
      font-size: 10vw;
      text-align: center;
      font-family: Theinhardt-Medium;
      -webkit-text-stroke: 1px rgb(7, 16, 172);
      color: rgb(255, 81, 0);
    }

    footer div h1 span{
      font-family: TimesNewerRoman;
      -webkit-text-stroke: 1px rgb(7, 16, 172);
      color: rgb(255, 81, 0);
    }

    .slider_landing_page{
      flex-direction: column;
    }

    .slider_landing_page div{
      width: 100%;
    }

    .slider_landing_page div img{
      width: 65%;
    }

    .sticky_container ul li{
      font-size: 5.45vw;
      font-family: Westminster;
    }

    .full_committee_container_inner{
      flex-direction: column;
    }

    .full_committee_container_inner div:last-child{
      margin-left: 0;
    }

    .full_committee_container_inner div:first-child{
      border-right: 0;
      border-bottom: 2px solid rgb(7, 16, 172);
      padding-right: 0;
      margin-bottom: 5vw;
      padding-bottom: 5vw;
    }

    .full_committee_container_inner div{
      width: 100%;
    }

    .full_committee_container_inner div h4{
      font-size: 5vw;
    }

    .main_text_container_inner_contexte span,
    .main_text_container_inner_argument div span{
      font-size: 10vw;
    }

    .main_text_container_inner_contexte p{
      line-height: 1.4;
      color: black;
      font-family: Theinhardt-Medium;
      font-size: 4.5vw;
    }

    .main_text_container_inner_argument_body{
      flex-direction: column;
    }

    .main_text_container_inner_argument_body div{
      width: 100%;
    }

    .main_text_container_inner_argument_body div:first-child{
      padding-right: 0;
      border-right: 0;
      border-bottom: 2px solid rgb(7, 16, 172);
    }

    .main_text_container_inner_argument_body div:last-child{
      padding-left: 0;
    }

    .main_text_container_inner_argument_body div{
      line-height: 1.4;
      color: black;
      font-family: TimesNewerRoman;
      font-size: 4.5vw;
      margin-top: 5vw;
    }

    i {
        font-style: normal;
        color: rgb(7, 16, 172);
        font-family: Westminster;
        font-size: 4.9vw;
    }

    .main_text_container_inner_argument_body p{
      margin-bottom: 5vw;
    }

    .main_text_container_inner_argument_body_last{
      font-size: 5.5vw;
    }

    .notes_container_inner{
      flex-direction: column;
    }

    .notes_container_inner div{
      width: 100%;
    }

    .notes_container_inner div p{
      font-size: 4vw;
    }

    .guidelines_and_button_container, .notes_container,
    .full_title_container,
    .bottom_info_main_container,
    .inscription_button_main_container,
    .main_text_container{
      padding-bottom: 15vw;
    }

    .proposition_text_main_container_inner .proposition_text_main_container_inner_headlines span,
    .bottom_info_container_headlines h1{
      font-size: 10vw;
    }

    .proposition_text_main_container p{
      line-height: 1.4;
      color: black;
      font-family: Theinhardt-Medium;
      font-size: 4.5vw;
    }

    .proposition_text_main_container_span_list_container{
      flex-direction: column;
    }

    .proposition_text_main_container_span_list_container div{
      width: 100%;
      padding-right: 0;
    }

    .proposition_text_main_container_span_list_container div:first-child{
      border-right: 0;
    }

    .proposition_text_main_container_span_list_container ul{
      padding-left: 0;
    }

    .proposition_text_main_container_span_list_container .last_ul{
      padding-left: 0;
    }

    .guidelines_container{
      flex-direction: column;
    }

    .guidelines_container div{
      width: 100%;
    }

    .guidelines_container div:last-child{
      padding-left: 0;
    }

    .proposition_text_main_container_span_list_container h1,
    .guidelines_container div span,
    .proposition_text_main_container_span_list_container ul li span{
      font-size: 5.5vw;
    }

    .proposition_text_main_container_span_list_container ul li,
    .guidelines_container div{
      line-height: 1.4;
      color: black;
      font-family: TimesNewerRoman;
      font-size: 4.5vw;
      margin-top: 5vw;
      margin-bottom: 0;
    }

    .guidelines_and_button_container_inner{
      padding-bottom: 0;
      padding-top: 0;
    }

    .bottom_info_container_body h1,
    .bottom_info_container_body h1 span{
      font-size: 8vw;
    }

    .bottom_info_container_secondary{
      flex-direction: column;
    }

    .bottom_info_container_secondary div{
      width: 100%;
    }

    .bottom_info_container_secondary div:first-child{
      border-right: 0;
      padding-right: 0;
      border-bottom: 2px solid rgb(7, 16, 172);
      margin-bottom: 5vw;
      padding-bottom: 5vw;
    }

    .bottom_info_container_secondary div:last-child{
      padding-left: 0;
    }

    .last_li{
      font-size: 5.5vw !important;
    }

    .bottom_info_container_secondary div h1{
      font-size: 5.5vw;
    }

    .inscription_button_main_container{
      margin-top: 15vw;
    }

    .inscription_button_main_container_inner{
      padding-top: 0;
    }

    .inscription_button_main_container_inner img{
      width: 70%;
      position: relative;
      z-index: 1;
    }

    .sticky_container ul li:last-child{
      margin-bottom: 3vw;
    }

    .sticky_container ul div img{
      display: none;
    }

    .credits_main_container_inner{
      flex-direction: column;
    }

    .credits_main_container_inner div{
      width: 100%;
    }

    .credits_main_container_inner div h2{
      font-size: 4vw;
    }

  }


  @media only screen
  and (min-device-width: 480px)
  and (max-device-width: 813px)
  and (orientation: landscape) {

    .sticky_container ul li,
    .proposition_text_main_container_span_list_container h1,
    .proposition_text_main_container_span_list_container ul li span,
    .guidelines_container div span,
    .bottom_info_container_secondary div h1,
    .main_text_container_inner_argument_body_last{
      font-size: 3.45vw;
    }

    header div h1{
      font-size: 6vw;
    }

    footer div h1{
      font-size: 6vw;
    }

    .full_committee_container_inner div h4{
      font-size: 3vw;
    }

    .main_text_container_inner_contexte p,
    .guidelines_container div,
    .notes_container_inner div p,
    .proposition_text_main_container p,
    .proposition_text_main_container_span_list_container ul li,
    .main_text_container_inner_argument_body div,
    i{
      font-size: 2.2vw;
    }

    .sticky_container ul div img{
      display: none;
    }

  }

